import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

function Contact() {
    return (
        <>
            <Grid container component="main" sx={{height: '100vh'}}>
                <CssBaseline/>
                <Grid
                    item
                    md={7}
                    xs={12}
                    sm={12}
                    sx={{
                        backgroundColor: "#4C4D91",
                    }}
                >
                    <div style={{
                        position: "relative",
                        display: "block",
                        backgroundColor: "#e2e2e2",
                        border: "none",
                        width: " 100%",
                        height: " 100%",
                        mixBlendMode: "luminosity"
                    }}>
                        <iframe
                            style={{
                                position: "relative",
                                display: "block",
                                backgroundColor: "#e2e2e2",
                                border: "none",
                                width: " 100%",
                                height: " 100%",
                            }}
                            title={"BEETs Lounge"}
                            src="https://maps.google.com/maps?hl=en&amp;q=36%20Pipers%20Row+(BEETs%20Lounge)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            allowFullScreen>
                        </iframe>
                    </div>

                </Grid>
                <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            Contact us
                        </Typography>
                        <Box component="form" noValidate sx={{mt: 1}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="fullName"
                                label="Full Name"
                                name="fullName"
                                autoComplete="fullName"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="message"
                                label="Message"
                                name="message"
                                autoComplete="message"
                                autoFocus
                                multiline
                                minRows={4}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2, backgroundColor: "#495194"}}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default Contact;
