import { Box, Container, styled, Typography } from "@mui/material";
import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import heroImg from "../../media/vip.jpg";

const VIP = () => {

    const PropertiesTextBox = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    }));

    const CustomBox = styled(Box)(({ theme }) => ({
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing(5),
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
        },
    }));

    const Title = styled(Typography)(({ theme }) => ({
        fontSize: "64px",
        color: "#000336",
        fontWeight: "bold",
        margin: theme.spacing(4, 0, 4, 0),
        [theme.breakpoints.down("sm")]: {
            fontSize: "40px",
        },
    }));

    return (
        <>
            <Box sx={{
                minHeight: "50vh",
                backgroundImage: 'url(' + heroImg + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                boxShadow: "inset 0 0 0 99999px rgba(120, 120, 255, 0.3)",
            }}>
                <Container>
                    <CustomBox>
                        <Box sx={{flex: "1"}}>
                            <Title variant="h1"
                                   sx={{mt: 20,color: "#fff"}}
                            >
                                Elevate Your Experience
                            </Title>
                            <Typography
                                variant="body2"
                                sx={{fontSize: "18px", color: "#fff", my: 4}}
                            >
                                Unlock the VIP Lifestyle Today!
                            </Typography>

                        </Box>
                    </CustomBox>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: "#F5FAFE", py: 4 }}>
                <Container>
                    <PropertiesTextBox>
                        <Typography
                            sx={{ color: "#000339", fontSize: "35px", fontWeight: "bold" }}
                        >
                            VIP Membership Club
                        </Typography>
                        <Typography sx={{ color: "#5A6473", fontSize: "16px", mt: 1 }}>
                            At BEETs Lounge, we are dedicated to providing our members with an unparalleled experience of luxury, exclusivity, and personalized service.
                            Our VIP membership offers a host of benefits designed to enhance every aspect of your club experience and create unforgettable memories.
                        </Typography>
                        <Typography
                            sx={{ marginTop: "1em", marginBottom: "1em", color: "#000339", fontSize: "28px", fontWeight: "bold" }}
                        >
                            Why Choose VIP Membership?
                        </Typography>
                        <div>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Exclusive Access
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    Gain access to exclusive areas, events, and amenities reserved only for VIP members. Enjoy private lounges, premium seating, and priority reservations.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Personalized Service
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    Experience personalized service tailored to your preferences. Our dedicated concierge team is committed to fulfilling your every need and ensuring a seamless and memorable experience.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Luxurious Amenities
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    Indulge in luxurious amenities and privileges, including complimentary spa treatments, gourmet dining experiences, and VIP parking.
                                </AccordionDetails>
                            </Accordion>

                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4-content"
                                    id="panel4-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Networking Opportunities
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    Connect with like-minded individuals and influential leaders from various industries through exclusive networking events and gatherings.
                                </AccordionDetails>
                            </Accordion>

                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4-content"
                                    id="panel4-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Priority Booking
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    Enjoy priority booking for club facilities, including golf courses, tennis courts, and event spaces, ensuring you have access to your preferred amenities whenever you desire.
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </PropertiesTextBox>
                </Container>
            </Box>
        </>

    );
};

export default VIP;
