import * as React from "react";
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import ContactsIcon from "@mui/icons-material/Contacts";
import logoImg from "../media/logo.png";
import {Container} from "@mui/system";
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
} from "@mui/material";
import {useEffect, useState} from "react";
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import {useLocation, useNavigate, NavLink} from 'react-router-dom';
import facebook from "../media/facebook.svg";
import apple from "../media/apple.svg";
import google from "../media/google.svg";
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import GradeIcon from '@mui/icons-material/Grade';
import BadgeIcon from '@mui/icons-material/Badge';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AboutIcon from '@mui/icons-material/Info';

export const Navbar = () => {

    const [register, setRegister] = useState(false);
    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/register") {
            setRegister(false);
        } else {
            setRegister(true);
        }
    }, [location]);

    const [mobileMenu, setMobileMenu] = useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.type === "Tab" || event.type === "Shift")
        ) {
            return;
        }

        setMobileMenu({...mobileMenu, [anchor]: open});
    };

    const [openDialog, setOpenDialog] = React.useState(false);

    const navigateToRegister = () => {
        navigate("/register");
    };

    const navigateToPage = (page) => {
        if (page  === "Home") {
            navigate("/");
        } else if (page === "Menus") {
            navigate("/menus");
        } else if (page  === "Events") {
            navigate("/events");
        } else if (page  === "VIP Membership") {
            navigate("/vip");
        } else if (page  === "Contact") {
            navigate("/contact");
        } else if (page  === "Careers") {
            navigate("/careers");
        } else if (page  === "Partners") {
            navigate("/partners");
        } else if (page  === "About Us") {
            navigate("/about");
        }
    };

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = (value) => {
        setOpenDialog(false);
    };

    const list = (anchor) => (
        <Box
            sx={{width: anchor === "top" || anchor === "bottom" ? "auto" : 250}}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {["Home", "About Us", "Menus", "Events", "VIP Membership", "Contact", "Partners", "Careers"].map(
                    (text, index) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton onClick={() => navigateToPage(text)}>
                                <ListItemIcon>
                                    {index === 0 && <HomeIcon/>}
                                    {index === 1 && <AboutIcon/>}
                                    {index === 2 && <RestaurantMenuIcon/>}
                                    {index === 3 && <EventAvailableIcon/>}
                                    {index === 4 && <GradeIcon/>}
                                    {index === 5 && <ContactsIcon/>}
                                    {index === 6 && <HandshakeIcon/>}
                                    {index === 7 && <BadgeIcon/>}
                                </ListItemIcon>
                                <ListItemText primary={text}/>
                            </ListItemButton>
                        </ListItem>
                    )
                )}
            </List>
        </Box>
    );

    const NavbarLinksBox = styled(Box)(({theme}) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: theme.spacing(3),
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    }));

    const CustomMenuIcon = styled(MenuIcon)(({theme}) => ({
        cursor: "pointer",
        display: "none",
        color: "#fff",
        marginRight: theme.spacing(2),
        [theme.breakpoints.down("md")]: {
            display: "block",
        },
    }));

    const NavbarContainer = styled(Container)(({theme}) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#01020a",
        minWidth: "100%",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(2),
        },
    }));

    const NavbarLogo = styled("img")(({theme}) => ({
        cursor: "pointer",
        [theme.breakpoints.down("md")]: {
            width: "100px",
        },
    }));

    return (
        <NavbarContainer>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "2.5rem",
                }}
            >
                <Box sx={{display: "flex", alignItems: "center"}}>
                    <CustomMenuIcon onClick={toggleDrawer("left", true)}/>
                    <Drawer
                        anchor="left"
                        open={mobileMenu["left"]}
                        onClose={toggleDrawer("left", false)}
                    >
                        {list("left")}
                    </Drawer>
                    <NavbarLogo width={200} src={logoImg} alt="logo"/>
                </Box>

                <NavbarLinksBox>
                    <NavLink to={"/"} variant="body2">Home</NavLink>
                    <NavLink to={"/about"} variant="body2">About US</NavLink>
                    <NavLink to={"/menus"} variant="body2">Menus</NavLink>
                    <NavLink to={"/events"} variant="body2">Events</NavLink>
                    <NavLink to={"/vip"} variant="body2">VIP Membership</NavLink>
                    <NavLink to={"/contact"} variant="body2">Contact</NavLink>
                    <NavLink to={"/partners"} variant="body2">Partners</NavLink>
                    <NavLink to={"/careers"} variant="body2">Careers</NavLink>
                </NavbarLinksBox>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                }}
            >
                {register && <Button
                    variant="outlined"
                    sx={{color: "#495194"}}
                    color={"primary"}
                    onClick={navigateToRegister}
                >Sign Up</Button>
                }
                <Button
                    sx={{backgroundColor: "#495194"}}
                    variant="contained"
                    onClick={handleClickOpen}
                >Login</Button>
            </Box>

            <SimpleDialog
                open={openDialog}
                onClose={handleClose}
            />
        </NavbarContainer>
    );
};

function SimpleDialog(props) {
    const {onClose, open} = props;

    const handleClose = () => {
        onClose();
    };

    // const handleListItemClick = (value) => {
    //     onClose();
    // };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 4,
                        marginBottom: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, backgroundColor: "#000"}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary"/>}
                            label="Remember me"
                        />
                        <Button
                            variant="contained"
                            type="submit"
                            fullWidth
                            sx={{mt: 3, mb: 2, backgroundColor: "#495194"}}
                        >
                            Sign In
                        </Button>
                        <Grid container sx={{mb: 1}}>
                            <Grid item xs>
                                <Link href="#"
                                      variant="body2"
                                      sx={{ color: "#495194"}}
                                >
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#"
                                      variant="body2"
                                      sx={{ color: "#495194"}}
                                >
                                    {"Register"}
                                </Link>
                            </Grid>
                        </Grid>

                        <Typography component="h1" variant="h5" sx={{ marginBottom: "0.8em" }}>
                            Or sign in with:
                        </Typography>

                        <Grid container>
                            <Grid item>
                                <Button
                                    sx={{ backgroundColor: "#fff", marginRight: "0.8em" }}
                                    variant="contained"
                                    startIcon={<Avatar src={facebook} />}
                                >
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    sx={{ backgroundColor: "#fff", marginRight: "0.8em" }}
                                    variant="contained"
                                    startIcon={<Avatar src={apple} />}
                                >
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    sx={{ backgroundColor: "#fff" }}
                                    variant="contained"
                                    startIcon={<Avatar src={google} />}
                                >
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default Navbar;
