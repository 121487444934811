import { Box, Container, styled, Typography, Grid } from "@mui/material";
import React from "react";
import WolverhamptonUniversity from "../../media/wolverhampton-university.svg";
import WolverhamptonCouncil from "../../media/wolverhampton-council.svg";
import BeetTechnologies from "../../media/beettechnologies.png";
import ImGarbage from "../../media/imgarbage.png";
import VeteranApp from "../../media/veteranapp.svg";
import Assembly from "../../media/assembly.svg";
import Rio from "../../media/rio.jpg";
import Sia from "../../media/sia.png";

const Partners = ({backgroundColor}) => {

    const PropertiesTextBox = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    }));

    const PartnerImage = styled("img")(({ theme }) => ({
        width: "300px",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "150px",
        },
    }));

    const PartnerGrid = styled(Grid)(({ theme }) => ({
        xs:4,
        [theme.breakpoints.down("md")]: {
            xs:6
        },
    }));

    return (
        <Box sx={{ backgroundColor: backgroundColor, py: 5 }}>
            <Container>
                <PropertiesTextBox>
                    <Typography
                        sx={{ color: "#000339", fontSize: "35px", fontWeight: "bold" }}
                    >
                        Our Partners
                    </Typography>
                    <Typography sx={{ color: "#5A6473", fontSize: "16px", mt: 1, mb: "3em" }}>
                        Bringing together the best partnerships for you. We've got the right partnerships to offer you best-of-breed expertise and services.
                    </Typography>

                    <div>
                        <Box sx={{flexGrow: 1}}>
                            <Grid container justifyContent="center"  spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <PartnerGrid item>
                                    <PartnerImage src={WolverhamptonUniversity}/>
                                </PartnerGrid>
                                <PartnerGrid item>
                                    <PartnerImage src={WolverhamptonCouncil}/>
                                </PartnerGrid>
                                <PartnerGrid item>
                                    <PartnerImage src={BeetTechnologies}/>
                                </PartnerGrid>

                                <PartnerGrid item>
                                    <PartnerImage src={ImGarbage} />
                                </PartnerGrid>

                                <PartnerGrid item>
                                    <PartnerImage src={VeteranApp} />
                                </PartnerGrid>

                                <PartnerGrid item>
                                    <PartnerImage src={Assembly} />
                                </PartnerGrid>

                                <PartnerGrid item>
                                    <PartnerImage src={Rio} />
                                </PartnerGrid>

                                <PartnerGrid item>
                                    <PartnerImage src={Sia} />
                                </PartnerGrid>
                            </Grid>
                        </Box>
                    </div>
                </PropertiesTextBox>
            </Container>
        </Box>
    );
};

export default Partners;
