import { Box, Container, styled, Typography } from "@mui/material";
import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import Link from "@mui/material/Link";
import {useParams} from "react-router-dom";

const JobDescription = () => {

    const PropertiesTextBox = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    }));

    const jobs = [
        {
            id: "1",
            title: "Bartender",
            overview: "A bartender plays a vital role in creating memorable experiences for patrons by crafting and serving beverages in a welcoming and efficient manner. Bartenders are responsible for maintaining a clean and organized bar area, ensuring the satisfaction of customers, and upholding the establishment's standards of service.                         A bartender plays a crucial role in shaping the overall experience of patrons, and a successful candidate will demonstrate a passion for hospitality, a commitment to excellence in service, and a positive attitude towards teamwork and customer satisfaction.",
            responsibilities: [
                "Mix and serve alcoholic and non-alcoholic beverages according to standard recipes and patrons' preferences.",
                "Engage with customers in a friendly and professional manner, providing recommendations and offering personalized service.",
                "Maintain a clean and organized bar area, including stocking and replenishing supplies, cleaning glasses and utensils, and ensuring compliance with sanitation standards.",
                "Handle cash transactions accurately and efficiently, processing payments and providing change as needed.",
                "Monitor alcohol consumption and ensure responsible serving practices are followed to maintain a safe and enjoyable environment for all patrons.",
                "Collaborate with other staff members, such as servers and kitchen staff, to coordinate orders and provide seamless service.",
                "Stay informed about current beverage trends, new products, and promotions, and make recommendations to enhance the menu and drive sales.",
                "Adhere to all relevant legal regulations and company policies regarding the service of alcohol and the operation of the bar.",
                "Handle customer inquiries, complaints, and feedback promptly and professionally, striving to resolve issues to the satisfaction of the guest.",
                "Participate in training sessions and workshops to continuously improve skills and knowledge related to bartending techniques, mixology, and customer service."
            ],
            qualifications: [
                "Previous experience working as a bartender or in a similar role preferred.",
                "Excellent communication and interpersonal skills, with the ability to engage effectively with a diverse range of customers.",
                "Strong knowledge of different types of alcoholic beverages, cocktails, and mixology techniques.",
                "Ability to work efficiently in a fast-paced environment while maintaining a high level of accuracy and attention to detail.",
                "Basic math skills for handling cash transactions and calculating drink prices.",
                "Knowledge of relevant health and safety regulations and willingness to adhere to them at all times.",
                "Flexible schedule, including evenings, weekends, and holidays, as required by the demands of the position.",
            ],
        },
        {
            id: "2",
            title: "DJ",
            overview: "A Disc Jockey (DJ) is responsible for curating and presenting music playlists, creating a lively and engaging atmosphere, and ensuring the entertainment needs of patrons or event attendees are met. DJs often work in various settings, including clubs, bars, weddings, parties, and corporate events, where they use their expertise to set the mood and keep guests entertained through music selection and performance. A DJ plays a pivotal role in setting the tone and atmosphere of an event or venue through their music selection and performance. A successful candidate will demonstrate technical proficiency, musical creativity, and a knack for engaging with audiences to create unforgettable experiences.",
            responsibilities: [
                "Curate playlists and select appropriate music tracks based on the venue, event type, and audience demographics.",
                "Set up and operate DJ equipment, including turntables, mixers, controllers, and sound systems, ensuring optimal sound quality and performance.",
                "Read the crowd and adjust music selections and tempo to maintain energy levels and keep guests engaged and entertained.",
                "Mix and blend music seamlessly, using techniques such as beatmatching, scratching, and crossfading to create smooth transitions between songs.",
                "Interact with guests in a professional and engaging manner, taking song requests and incorporating them into the playlist when appropriate.",
                "Collaborate with event organizers, venue staff, and other entertainment professionals to coordinate timing, logistics, and special requests.",
                "Stay up-to-date with current music trends, popular artists, and new releases, incorporating fresh tracks and remixes into playlists to keep the music selection dynamic and relevant.",
                "Ensure compliance with copyright laws and licensing requirements when playing music in public venues, obtaining necessary permissions and paying royalties as needed.",
                "Maintain and troubleshoot DJ equipment, ensuring it is properly functioning and making adjustments as necessary during performances.",
                "Create promotional materials, including mixtapes, demos, and social media content, to market services and attract new clients or bookings."
            ],
            qualifications: [
                "Previous experience working as a DJ in clubs, bars, or events preferred.",
                "Proficiency in operating DJ equipment and software, with knowledge of various mixing techniques and music genres.",
                "Excellent music selection skills, with a diverse knowledge of music across different eras and styles.",
                "Strong communication and interpersonal skills, with the ability to interact effectively with diverse audiences.",
                "Ability to work flexible hours, including evenings, weekends, and holidays, to accommodate the demands of the job.",
                "Creative flair and a passion for music and entertainment, with the ability to create memorable experiences for guests through music and performance.",
                "Basic knowledge of audio engineering principles and sound reinforcement techniques is a plus.",
            ],
        },
        {
            id: "3",
            title: "Waiter/Waitress",
            overview: "A waiter/waitress, also known as a server, plays a crucial role in delivering excellent customer service by taking orders, serving food and beverages, and attending to the needs of diners in a restaurant or similar establishment. They are responsible for ensuring guests have a pleasant dining experience from the moment they are seated until they leave. A waiter/waitress plays a vital role in ensuring guests feel welcome and satisfied during their visit to a restaurant. A successful candidate will demonstrate excellent customer service skills, attention to detail, and a positive attitude towards teamwork and guest satisfaction.",
            responsibilities: [
                "Greet and seat guests in a courteous and professional manner, presenting menus and providing information about daily specials or promotions.",
                "Take food and beverage orders accurately, using a POS (Point of Sale) system if applicable, and ensure orders are entered promptly and correctly.",
                "Relay orders to the kitchen or bar staff and coordinate with other servers to ensure efficient delivery of food and drinks to tables.",
                "Serve food and beverages to guests, following established service procedures and presentation standards.",
                "Check in with diners to ensure satisfaction with their meals, address any concerns or special requests, and offer dessert or additional drinks as appropriate.",
                "Monitor table turnover and maintain awareness of seating capacity to maximize efficiency and accommodate incoming guests.",
                "Anticipate guests' needs and provide attentive service throughout their dining experience, including refilling drinks, clearing plates, and offering assistance as needed.",
                "Collaborate with kitchen and bar staff to ensure orders are prepared and served in a timely manner, communicating any special requests or modifications.",
                "Handle cash and credit card transactions accurately, processing payments and providing receipts to guests.",
                "Maintain cleanliness and organization in the dining area, including clearing and resetting tables, cleaning spills, and ensuring adequate supplies of condiments and utensils.",
                "Adhere to all food safety and sanitation guidelines, including proper handling of food and beverages and regular handwashing.",
                "Attend staff meetings and training sessions as required, staying informed about menu changes, service standards, and company policies.",
                "Upsell menu items and promote special promotions or events to increase sales and enhance the overall guest experience.",
                "Handle customer inquiries, complaints, and feedback professionally and promptly, escalating issues to management as needed to ensure guest satisfaction."

            ],
            qualifications: [
                "Previous experience working as a waiter/waitress or in a customer service role preferred.",
                "Excellent communication and interpersonal skills, with the ability to interact positively with guests and team members.",
                "Strong attention to detail and ability to multitask in a fast-paced environment.",
                "Basic math skills for processing orders, handling payments, and making change.",
                "Ability to work effectively as part of a team and independently with minimal supervision.",
                "Flexibility to work evenings, weekends, and holidays as required by the demands of the position.",
                "Knowledge of food and beverage menu items, including ingredients, preparation methods, and allergen information.",
                "Commitment to providing exceptional service and creating memorable dining experiences for guests.",
            ],
        },
        {
            id: "4",
            title: "Designated Premises Supervisor (DPS)",
            overview: "The Designated Premises Supervisor (DPS) holds a pivotal role in licensed premises, such as bars, pubs, clubs, and restaurants, ensuring that the establishment operates in accordance with all relevant laws and regulations related to the sale and service of alcohol. The DPS is responsible for overseeing the day-to-day management of the premises and ensuring that all staff members are properly trained and compliant with licensing requirements. The Designated Premises Supervisor plays a critical role in ensuring that licensed premises operate safely, responsibly, and in compliance with all relevant laws and regulations. A successful DPS will demonstrate strong leadership skills, a thorough understanding of licensing requirements, and a commitment to promoting responsible alcohol service practices.",
            responsibilities: [
                "Act as the primary point of contact and legal representative for the licensed premises in matters related to the sale and service of alcohol.",
                "Ensure that the premises operate in compliance with all relevant licensing laws, regulations, and conditions set forth by licensing authorities.",
                "Maintain an up-to-date understanding of licensing regulations and legal requirements, including changes in legislation, and implement necessary measures to ensure compliance.",
                "Take responsibility for the conduct of staff members regarding the sale and service of alcohol, ensuring that they are properly trained in responsible alcohol service practices.",
                "Provide ongoing training and support to staff members on relevant topics, including age verification, refusal of service, and handling of intoxicated patrons.",
                "Monitor the premises to ensure that alcohol is served responsibly and that any incidents or issues are addressed promptly and appropriately.",
                "Maintain accurate records of alcohol sales and incidents, including incident reports, staff training records, and any communications with licensing authorities.",
                "Act as a liaison between the premises and licensing authorities, responding to inquiries, attending hearings or meetings, and representing the interests of the establishment.",
                "Collaborate with management and staff to develop and implement policies and procedures related to alcohol service, safety, and security.",
                "Conduct regular inspections of the premises to identify any areas of concern or potential risks, such as underage drinking, over-service, or health and safety hazards.",
                "Work closely with other departments or agencies, such as law enforcement, health inspectors, or fire marshals, to address any issues or concerns related to the operation of the premises.",
                "Stay informed about industry best practices, trends, and developments in alcohol licensing and service, and make recommendations for improvements or enhancements to operations.",
            ],
            qualifications: [
                "Previous experience working in a licensed premises, such as a bar, pub, or restaurant, preferred.",
                "Knowledge of relevant licensing laws, regulations, and best practices related to the sale and service of alcohol.",
                "Strong leadership and management skills, with the ability to oversee and train staff members effectively.",
                "Excellent communication and interpersonal skills, with the ability to interact with staff, patrons, and licensing authorities professionally and diplomatically.",
                "Attention to detail and ability to maintain accurate records and documentation.",
                "Ability to work independently and make decisions under pressure, prioritizing the safety and legal compliance of the premises.",
                "Flexibility to work evenings, weekends, and holidays as required by the demands of the position.",
                "Certification or training in alcohol licensing laws and responsible alcohol service practices may be required, depending on local regulations.",
            ],
        },



        // {
        //     id: "5",
        //     title: "Dishwasher",
        //     overview: "The Designated Premises Supervisor (DPS) holds a pivotal role in licensed premises, such as bars, pubs, clubs, and restaurants, ensuring that the establishment operates in accordance with all relevant laws and regulations related to the sale and service of alcohol. The DPS is responsible for overseeing the day-to-day management of the premises and ensuring that all staff members are properly trained and compliant with licensing requirements. The Designated Premises Supervisor plays a critical role in ensuring that licensed premises operate safely, responsibly, and in compliance with all relevant laws and regulations. A successful DPS will demonstrate strong leadership skills, a thorough understanding of licensing requirements, and a commitment to promoting responsible alcohol service practices.",
        //     responsibilities: [
        //         "Act as the primary point of contact and legal representative for the licensed premises in matters related to the sale and service of alcohol.",
        //         "Ensure that the premises operate in compliance with all relevant licensing laws, regulations, and conditions set forth by licensing authorities.",
        //         "Maintain an up-to-date understanding of licensing regulations and legal requirements, including changes in legislation, and implement necessary measures to ensure compliance.",
        //         "Take responsibility for the conduct of staff members regarding the sale and service of alcohol, ensuring that they are properly trained in responsible alcohol service practices.",
        //         "Provide ongoing training and support to staff members on relevant topics, including age verification, refusal of service, and handling of intoxicated patrons.",
        //         "Monitor the premises to ensure that alcohol is served responsibly and that any incidents or issues are addressed promptly and appropriately.",
        //         "Maintain accurate records of alcohol sales and incidents, including incident reports, staff training records, and any communications with licensing authorities.",
        //         "Act as a liaison between the premises and licensing authorities, responding to inquiries, attending hearings or meetings, and representing the interests of the establishment.",
        //         "Collaborate with management and staff to develop and implement policies and procedures related to alcohol service, safety, and security.",
        //         "Conduct regular inspections of the premises to identify any areas of concern or potential risks, such as underage drinking, over-service, or health and safety hazards.",
        //         "Work closely with other departments or agencies, such as law enforcement, health inspectors, or fire marshals, to address any issues or concerns related to the operation of the premises.",
        //         "Stay informed about industry best practices, trends, and developments in alcohol licensing and service, and make recommendations for improvements or enhancements to operations.",
        //     ],
        //     qualifications: [
        //         "Previous experience working in a licensed premises, such as a bar, pub, or restaurant, preferred.",
        //         "Knowledge of relevant licensing laws, regulations, and best practices related to the sale and service of alcohol.",
        //         "Strong leadership and management skills, with the ability to oversee and train staff members effectively.",
        //         "Excellent communication and interpersonal skills, with the ability to interact with staff, patrons, and licensing authorities professionally and diplomatically.",
        //         "Attention to detail and ability to maintain accurate records and documentation.",
        //         "Ability to work independently and make decisions under pressure, prioritizing the safety and legal compliance of the premises.",
        //         "Flexibility to work evenings, weekends, and holidays as required by the demands of the position.",
        //         "Certification or training in alcohol licensing laws and responsible alcohol service practices may be required, depending on local regulations.",
        //     ],
        // },
    ]

    const { id } = useParams();
    const found = jobs.find((element) => element.id === id);

    return (
        <Box sx={{ backgroundColor: "#F5FAFE", py: 5 }}>
            <Container>
                <PropertiesTextBox>
                    <Typography
                        sx={{ color: "#000339", fontSize: "35px", fontWeight: "bold", mb: "1em" }}
                    >
                        {found.title}
                    </Typography>
                    <Typography
                        sx={{ color: "#000339", fontSize: "28px", fontWeight: "bold" }}
                    >
                        Description:
                    </Typography>

                    <Typography sx={{ color: "#5A6473", fontSize: "16px", mt: 1 }}>
                        {found.overview}
                    </Typography>
                    <Typography
                        sx={{ marginTop: "1em", marginBottom: "1em", color: "#000339", fontSize: "28px", fontWeight: "bold" }}
                    >
                        Key Responsibilities:
                    </Typography>
                    <div>
                        <Card variant="outlined">
                            <CardContent>
                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    {found.responsibilities.map((value) => {
                                        return (
                                            <ListItem
                                                key={value}
                                                disablePadding
                                            >
                                                <ListItemButton role={undefined}  dense>
                                                    <ListItemIcon>
                                                        <CircleIcon sx={{width: 16}} />
                                                    </ListItemIcon>
                                                    <ListItemText sx={{color: "#000339"}} primary={`${value}`} />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </CardContent>
                        </Card>
                    </div>


                    <Typography
                        sx={{ marginTop: "1em", marginBottom: "1em", color: "#000339", fontSize: "28px", fontWeight: "bold" }}
                    >
                        Qualifications:
                    </Typography>
                    <div>
                        <Card variant="outlined">
                            <CardContent>
                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    {found.qualifications.map((value) => {
                                        return (
                                            <ListItem
                                                key={value}
                                                disablePadding
                                            >
                                                <ListItemButton role={undefined}  dense>
                                                    <ListItemIcon>
                                                        <CircleIcon sx={{width: 16}} />
                                                    </ListItemIcon>
                                                    <ListItemText sx={{color: "#000339"}} primary={`${value}`} />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </CardContent>
                        </Card>
                    </div>

                    <Typography
                        sx={{ marginTop: "1em", marginBottom: "1em", color: "#000339", fontSize: "28px", fontWeight: "bold" }}
                    >
                        Apply:
                    </Typography>
                    <Typography sx={{ color: "#5A6473", fontSize: "16px", mt: 1 }}>
                        Send application to <Link>jobs@beetslounge.co.uk</Link>
                    </Typography>
                </PropertiesTextBox>
            </Container>
        </Box>
    );
};

export default JobDescription;
