
export const jobs = [
  {
    id: "1",
    date: "April, 02, 2024",
    category: "Service",
    position: "Bartenders",
  },
  {
    id: "2",
    date: "April, 02, 2024",
    category: "Service",
    position: "DJ",
  },
  {
    id: "3",
    date: "April, 02, 2024",
    category: "Service",
    position: "Waiters/Waitresses",
  },
  {
    id: "4",
    date: "April, 02, 2024",
    category: "Service",
    position: "Designated Premises Supervisor (DPS)",
  },
];

export const jobs1 = [

  // {
  //   id: "5",
  //   date: "April, 02, 2024",
  //   category: "Kitchen",
  //   position: "Dishwasher",
  // },
  // {
  //   id: "6",
  //   date: "April, 02, 2024",
  //   category: "Kitchen",
  //   position: "Chef",
  // },
  // {
  //   id: "7",
  //   date: "April, 02, 2024",
  //   category: "Media",
  //   position: "Photographer/Videographer",
  // },
  // {
  //   id: "8",
  //   date: "April, 02, 2024",
  //   category: "Media",
  //   position: "Social Media Manager",
  // },
];

