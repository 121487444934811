import { Box, Container, styled, Typography } from "@mui/material";
import React from "react";
import Paper from "@mui/material/Paper";

const TermsAndConditions = () => {

    const PropertiesTextBox = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    }));

    return (
        <Box sx={{ backgroundColor: "#F5FAFE", py: 5 }}>
            <Container>
                <PropertiesTextBox>
                    <Paper elevation={2}>
                        <Box p={3}>
                            <Typography
                                sx={{ color: "#000339", fontSize: "35px", fontWeight: "bold" }}
                            >
                                Terms and conditions
                            </Typography>

                            <Typography sx={{ color: "#5A6473", fontSize: "16px", mt: 2 }}>
                                Coming soon!
                            </Typography>
                        </Box>
                    </Paper>
                </PropertiesTextBox>
            </Container>
        </Box>
    );
};

export default TermsAndConditions;
