import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import google from "../../media/google.svg";
import facebook from "../../media/facebook.svg";
import apple from "../../media/apple.svg";
import bg from "../../media/register-bg.jpg";

function Register() {
    return (
        <>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(' + bg + ')',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            Registration
                        </Typography>
                        <Box component="form" noValidate  sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                name="firstName"
                                autoComplete="firstName"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="lastName"
                                autoFocus
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, backgroundColor: "#495194" }}
                            >
                                Submit
                            </Button>

                            <Typography component="h1" variant="h5" sx={{ marginBottom: "0.8em" }}>
                                Or register with:
                            </Typography>

                            <Grid container>
                                <Grid item>
                                    <Button
                                        sx={{ backgroundColor: "#fff", marginRight: "0.8em" }}
                                        variant="contained"
                                        startIcon={<Avatar src={facebook} />}
                                    >
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        sx={{ backgroundColor: "#fff", marginRight: "0.8em" }}
                                        variant="contained"
                                        startIcon={<Avatar src={apple} />}
                                    >
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        sx={{ backgroundColor: "#fff" }}
                                        variant="contained"
                                        startIcon={<Avatar src={google} />}
                                    >
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default Register;
