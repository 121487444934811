import Properties from "../Properties";

function Events() {
    return (
        <>
            <Properties/>
        </>
    );
}

export default Events;

