import { Box, styled, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import Navbar from "./Navbar";
import heroImg from "../media/bg-bg.jpg";
import heroImg1 from "../media/bg1-bg.jpg";
import heroImg2 from "../media/bg2-bg.jpg";
import Carousel from "react-material-ui-carousel";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const Hero = () => {

  const items = [
    {
      name: "Discover Tranquility, Indulgence, & Elegance",
      description: "Welcome to Your Ultimate Lounge Experience",
      image: heroImg
    },
    {
      name: "Excellence in the field",
      description: "If you are looking to get hired, promote a project or educate an audience, BEETs Lounge is the premier venue to showcase yourself and ideas. Look out for our events.",
      image: heroImg2
    },
    {
      name: "Where Elegance Meets Comfort",
      description: "Your Oasis in the Heart of the City.",
      image: heroImg1
    }
  ]

  // const navigate = useNavigate();

  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: "64px",
    color: "#000336",
    fontWeight: "bold",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
  }));

  // const navigateToBooking = () => {
  //   navigate("/booking");
  // };

  function Item(props)
  {
    const image = props.item.image
    return (
    <Box sx={{
      minHeight: "80vh",
      backgroundImage: 'url(' + image + ')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      boxShadow: "inset 0 0 0 99999px rgba(120, 120, 255, 0.4)",
    }}>
      <Container>
        <CustomBox>
          <Box sx={{flex: "1"}}>
            <Title variant="h1"
                   sx={{mt: 20,color: "#fff"}}
            >
              {props.item.name}
            </Title>
            <Typography
                variant="body2"
                sx={{fontSize: "18px", color: "#fff", my: 4}}
            >
              {props.item.description}
            </Typography>
            {/*<CustomButton*/}
            {/*    onClick={navigateToBooking}*/}
            {/*    backgroundColor="#0F1B4C"*/}
            {/*    color="#fff"*/}
            {/*    buttonText="BOOK TODAY"*/}
            {/*    heroBtn={true}*/}
            {/*/>*/}
          </Box>
        </CustomBox>
      </Container>
    </Box>
    )
  }

  return (
      <>
        <Navbar/>
        <Carousel
            NextIcon={<NavigateNextIcon/>}
            PrevIcon={<NavigateBeforeIcon/>}
            sx={{
              minHeight: "80vh",
              backgroundColor: '#F5FAFE',
            }}
            navButtonsProps={{
              style: {
                borderRadius: 0
              }
            }}

            animation={"slide"}
            navButtonsAlwaysVisible={true}
        >
          {
            items.map( (item, i) => <Item key={i} item={item} /> )
          }
        </Carousel>
      </>
  );
};

export default Hero;
