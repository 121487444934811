import { Box, Container, styled, Typography } from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import heroImg from "../../media/about.jpg";

const About = () => {
    const PropertiesTextBox = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    }));

    const CustomBox = styled(Box)(({ theme }) => ({
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing(5),
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
        },
    }));

    const Title = styled(Typography)(({ theme }) => ({
        fontSize: "64px",
        color: "#000336",
        fontWeight: "bold",
        margin: theme.spacing(4, 0, 4, 0),
        [theme.breakpoints.down("sm")]: {
            fontSize: "40px",
        },
    }));

    return (
        <>
            <Box sx={{
                minHeight: "50vh",
                backgroundImage: 'url(' + heroImg + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                boxShadow: "inset 0 0 0 99999px rgba(120, 120, 255, 0.3)",
            }}>
                <Container>
                    <CustomBox>
                        <Box sx={{flex: "1"}}>
                            <Title variant="h1"
                                   sx={{mt: 20,color: "#fff"}}
                            >
                               We are just opposite the Central Bus Station
                            </Title>
                            <Typography
                                variant="body2"
                                sx={{fontSize: "18px", color: "#fff", my: 4}}
                            >
                                Please come and enjoy with us.
                            </Typography>

                        </Box>
                    </CustomBox>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: "#F5FAFE", py: 4 }}>
                <Container>
                    <PropertiesTextBox>
                        <Typography
                            sx={{ color: "#000339", fontSize: "35px", fontWeight: "bold" }}
                        >
                            Welcome to BEETs Lounge
                        </Typography>
                        <Typography sx={{ color: "#5A6473", fontSize: "16px", mt: 1, mb: 4 }}>
                            Nestled in the heart of the city of Wolverhampton, BEETs Lounge stands as a beacon of sophistication and indulgence, offering a unique blend of culinary excellence, exquisite cocktails, and an ambiance that transcends ordinary dining experiences. <br/> Step into our world, where every moment is infused with warmth, elegance, and a touch of mystique.
                        </Typography>
                        <div>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        The Atmosphere
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    As you enter BEETs Lounge, you are enveloped in an atmosphere of timeless elegance and understated luxury. The soft glow of ambient lighting casts a gentle spell, setting the stage for an evening of enchantment. Plush seating arrangements beckon you to unwind and immerse yourself in the rhythmic pulse of the city, while subtle melodies float through the air, enhancing the sense of tranquility and allure.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Culinary Delights
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    Our culinary team, led by renowned chefs, crafts a menu inspired by global flavors and local influences, ensuring each dish is a masterpiece of taste and presentation. From small plates bursting with vibrant flavors to decadent mains that tantalize the senses, every bite is a journey of discovery. Pair your meal with a selection from our curated wine list or allow our expert mixologists to create a bespoke cocktail tailored to your preferences, elevating your dining experience to new heights.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        The Experience
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    Whether you're seeking a romantic evening for two, celebrating a special occasion with loved ones, or simply unwinding after a long day, BEETs Lounge offers an experience like no other. Lose yourself in conversation amidst the intimate ambiance of our private alcoves, or mingle with fellow connoisseurs in our stylish bar area. Impeccable service and attention to detail are the hallmarks of our hospitality, ensuring that every moment spent with us is memorable and cherished.
                                </AccordionDetails>
                            </Accordion>

                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4-content"
                                    id="panel4-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Events and Entertainment
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    Throughout the week, BEETs Lounge plays host to a variety of events and entertainment offerings, ranging from live music performances and DJ sets to themed nights and exclusive parties. Immerse yourself in the vibrant energy of the city's nightlife scene while sipping on expertly crafted cocktails and savoring delectable bites, creating memories that will last a lifetime.
                                </AccordionDetails>
                            </Accordion>

                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4-content"
                                    id="panel4-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Visit Us
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    Whether you're a seasoned epicurean, a cocktail enthusiast, or simply someone with a passion for refined experiences, BEETs Lounge invites you to embark on a journey of indulgence and discovery. Join us and elevate your dining and nightlife experience to new heights. We look forward to welcoming you into our world of enchantment and allure.
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </PropertiesTextBox>
                </Container>
            </Box>
        </>
    );
};

export default About;
