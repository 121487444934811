import house1 from "./media/event1.png";
import house2 from "./media/event2.png";
import house3 from "./media/event3.png";

import event4 from "./media/event4.png";
import event5 from "./media/event5.png";
import event6 from "./media/event6.png";
import event7 from "./media/event7.png";

export const events = [
  {
    id: "1",
    img: event6,
    price: "Free",
    address: "Grand Opening - 29th June 2024",
    bedrooms: 2,
    bathrooms: 2,
    space: 2000,
  },
  {
    id: "2",
    img: house2,
    price: "Free",
    address: "Beer Night",
    bedrooms: 3,
    bathrooms: 2,
    space: 2300,
  },
  {
    id: "6",
    img: house1,
    price: "Free",
    address: "Happy Hour",
    bedrooms: 4,
    bathrooms: 3,
    space: 3000,
  },
];

export const events1 = [
  {
    id: "4",
    img: event4,
    price: "Free",
    address: "Cocktail Party",
    bedrooms: 2,
    bathrooms: 2,
    space: 2000,
  },
  {
    id: "5",
    img: event5,
    price: "Free",
    address: "Trivia Night",
    bedrooms: 3,
    bathrooms: 2,
    space: 2300,
  },
  {
    id: "7",
    img: event7,
    price: "Free",
    address: "Standup Comedy Night",
    bedrooms: 3,
    bathrooms: 2,
    space: 2300,
  },
];

export const events2 = [
  {
    id: "3",
    img: house3,
    price: "£15",
    address: "Party with DJ RUGET",
    bedrooms: 4,
    bathrooms: 3,
    space: 3000,
  },
];

