import { Box, Container, styled, Typography } from "@mui/material";
import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import heroImg from "../../media/vip.avif";

const PrivateParties = () => {

    const PropertiesTextBox = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    }));

    const CustomBox = styled(Box)(({ theme }) => ({
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing(5),
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
        },
    }));

    const Title = styled(Typography)(({ theme }) => ({
        fontSize: "64px",
        color: "#000336",
        fontWeight: "bold",
        margin: theme.spacing(4, 0, 4, 0),
        [theme.breakpoints.down("sm")]: {
            fontSize: "40px",
        },
    }));

    return (
        <>
            <Box sx={{
                minHeight: "50vh",
                backgroundImage: 'url(' + heroImg + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                boxShadow: "inset 0 0 0 99999px rgba(120, 120, 255, 0.3)",
            }}>
                <Container>
                    <CustomBox>
                        <Box sx={{flex: "1"}}>
                            <Title variant="h1"
                                   sx={{mt: 20,color: "#fff"}}
                            >
                                Elevate Your Celebration, Unforgettable Private Parties at BEETs Lounge
                            </Title>
                            <Typography
                                variant="body2"
                                sx={{fontSize: "18px", color: "#fff", my: 4}}
                            >
                                Immerse yourself in an unparalleled experience of luxury and sophistication with private parties at BEETs Lounge.
                            </Typography>

                        </Box>
                    </CustomBox>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: "#F5FAFE", py: 4 }}>
                <Container>
                    <PropertiesTextBox>
                        <Typography
                            sx={{ color: "#000339", fontSize: "35px", fontWeight: "bold" }}
                        >
                            Our private party packages
                        </Typography>
                        <Typography sx={{ color: "#5A6473", fontSize: "16px", mt: 1, mb: 2 }}>
                            Escape the ordinary and indulge in the extraordinary with our exclusive private party experiences at BEETs Lounge.
                            Nestled in the heart of Wolverhampton, our stylish and sophisticated lounge sets the stage for unforgettable celebrations in an intimate setting.
                        </Typography>

                        <div>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Cocktail Masterclass
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    Offer guests the opportunity to learn the art of mixology from expert bartenders. Provide a hands-on experience where attendees can craft their own signature cocktails using premium spirits and fresh ingredients.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Wine Tasting Soiree
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    Curate a selection of fine wines from around the world and host a guided tasting session led by a sommelier. Pair each wine with delectable appetizers or small bites to complement the flavors and enhance the tasting experience.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Chef's Table Experience
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    Collaborate with a talented chef to create a customized menu featuring gourmet dishes inspired by seasonal ingredients and global flavors. Set up an intimate chef's table where guests can interact with the chef and savor an exclusive dining experience.
                                </AccordionDetails>
                            </Accordion>

                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4-content"
                                    id="panel4-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Live Music Lounge
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    Transform the lounge into a live music venue and showcase local musicians or bands performing a variety of genres, from jazz and blues to acoustic and indie. Create a cozy ambiance with mood lighting and comfortable seating arrangements for guests to relax and enjoy the music.
                                </AccordionDetails>
                            </Accordion>

                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4-content"
                                    id="panel4-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Themed Cocktail Party
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    Plan a themed cocktail party inspired by a specific era, destination, or cultural theme. Whether it's a 1920s Gatsby-inspired soirée, a tropical luau, or a glamorous Hollywood night, encourage guests to dress the part and enjoy themed cocktails, décor, and entertainment.
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </PropertiesTextBox>
                </Container>
            </Box>
        </>

    );
};

export default PrivateParties;
