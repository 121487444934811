import { styled, Typography, Link } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import fbIcon from "../media/fbicon.png";
import logoImg from "../media/logo.png";
import tiktokIcon from "../media/tiktokicon.png";
import snapchatIcon from "../media/snapchaticon.png";
import youtubeIcon from "../media/youtubeicon.png";
import instagramIcon from "../media/instagramicon.png";
import {useNavigate} from "react-router-dom";

const Footer = () => {

  const navigate = useNavigate();

  const CustomContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-around",
    gap: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  }));

  const LogoContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      marginBottom: theme.spacing(4),
    },
  }));

  const IconBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  }));

  const FooterLink = styled("span")(({ theme }) => ({
    fontSize: "16px",
    color: "#7A7A7E",
    fontWeight: "300",
    cursor: "pointer",
    "&:hover": {
      color: "#d3a4a4",
    },
  }));

  const CustomBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(4),
    },
  }));

  const navigateToAbout = () => {
    navigate("/about");
  };

  const navigateToGardens = () => {
    navigate("/gardens");
  };

  const navigateToPrivacyPolicy = () => {
    navigate("/privacy-policy");
  };

  const navigateToDeliveryPolicy = () => {
    navigate("/delivery-policy");
  };

  const navigateToTermsAndConditions = () => {
    navigate("/terms-and-conditions");
  };

  const navigateToDrinks = () => {
    navigate("/drinks");
  };

  const navigateToPrivateParties = () => {
    navigate("/private-parties");
  };

  const navigateToSundayRoast = () => {
    navigate("/sunday-roast");
  };

  const navigateToFaceBook = () => {
    navigate("/facebook");
  };

  const navigateToTikTok = () => {
    navigate("/tiktok");
  };

  const navigateToSnapchat = () => {
    navigate("/snapchat");
  };

  const navigateToInstagram = () => {
    navigate("/instagram");
  };

  const navigateToYoutube = () => {
    navigate("/youtube");
  };

  const navigateToMenus = () => {
    navigate("/menus");
  };

  return (
    <Box sx={{ paddingTop: 10, paddingBottom: 5, backgroundColor: "#01020a" }}>
        <LogoContainer>
          <CustomBox>
            <img width={200}  src={logoImg} alt="logo" style={{ maxWidth: "100%" }}/>
          </CustomBox>
        </LogoContainer>
        <CustomContainer>
          <CustomContainer>
            <Box>
              <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#d3a4a4",
                    fontWeight: "700",
                    mb: 2,
                  }}
              >
                Services
              </Typography>


              <FooterLink onClick={navigateToPrivateParties}>Private Parties</FooterLink>
              <br />
              <FooterLink onClick={navigateToDrinks}>Drinks & Cocktails</FooterLink>
              <br />
              <FooterLink onClick={navigateToSundayRoast}>Sunday Roast</FooterLink>
              <br />
              <FooterLink onClick={navigateToGardens}>Gardens & Shisha</FooterLink>
              <br />
              <FooterLink onClick={navigateToGardens}>Educational Events</FooterLink>
            </Box>
            <Box>
              <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#d3a4a4",
                    fontWeight: "700",
                    mb: 2,
                  }}
              >
                Lounge
              </Typography>
              <FooterLink onClick={navigateToMenus}>Menus</FooterLink>
              <br />
              <FooterLink onClick={navigateToAbout}>About Us</FooterLink>
              <br />
              <FooterLink onClick={navigateToTermsAndConditions}>Terms & Conditions</FooterLink>
              <br />
              <FooterLink onClick={navigateToPrivacyPolicy}>Privacy policy</FooterLink>
              {/*<br />*/}
              {/*<FooterLink onClick={navigateToDeliveryPolicy}>Delivery policy</FooterLink>*/}
            </Box>
            <Box>
              <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#d3a4a4",
                    fontWeight: "700",
                    mb: 2,
                  }}
              >
                Opening Hours
              </Typography>

              <FooterLink>Monday&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 12:00 PM - 02:00 AM</FooterLink>
              <br />
              <FooterLink>Tuesday&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 12:00 PM - 02:00 AM</FooterLink>
              <br />
              <FooterLink>Wednesday&nbsp;&nbsp;&nbsp;- 12:00 PM - 02:00 AM</FooterLink>
              <br />
              <FooterLink>Thursday&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 12:00 PM - 02:00 AM</FooterLink>
              <br />
              <FooterLink>Friday&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 12:00 PM - 04:00 AM</FooterLink>
              <br />
              <FooterLink>Saturday&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 12:00 PM - 04:00 AM</FooterLink>
              <br />
              <FooterLink>Sunday&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 12:00 PM - 03:00 AM</FooterLink>
            </Box>
            <Box>
              <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#d3a4a4",
                    fontWeight: "700",
                    mb: 2,
                  }}
              >
                Get In Touch
              </Typography>

              <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#7A7A7E",
                    fontWeight: "500",
                    mb: 2,
                  }}
              >
                Whether you have a question, <br /> feedback, or want to make a reservation,<br /> don't hesitate to reach out to us.
              </Typography>

              <FooterLink>Email: info@beetslounge.co.uk</FooterLink>
              <br />
              <FooterLink>Tel: + 44 ( 7586 ) 746998</FooterLink>
              <br />
              <br />
              <FooterLink>Address: </FooterLink>
              <br />
              <FooterLink>36 Pipers Row <br /> WV1 3JY<br/>Wolverhampton, UK </FooterLink>
              <br />
              <br />
              <br />

              <IconBox>
                <img
                    width={40}
                    src={fbIcon}
                     alt="fbIcon"
                     style={{ cursor: "pointer" }}
                    onClick={navigateToFaceBook}
                />
                <img
                    width={40}
                    src={tiktokIcon}
                    alt="linkedinIcon"
                    style={{ cursor: "pointer" }}
                    onClick={navigateToTikTok}
                />
                <img
                    width={40}
                    src={instagramIcon}
                    alt="linkedinIcon"
                    style={{ cursor: "pointer" }}
                    onClick={navigateToInstagram}
                />
                <img
                    width={40}
                    src={snapchatIcon}
                    alt="linkedinIcon"
                    style={{ cursor: "pointer" }}
                    onClick={navigateToSnapchat}
                />
                {/*<img*/}
                {/*    width={40}*/}
                {/*    src={youtubeIcon}*/}
                {/*    alt="linkedinIcon"*/}
                {/*    style={{ cursor: "pointer" }}*/}
                {/*    onClick={navigateToYoutube}*/}
                {/*/>*/}
              </IconBox>
            </Box>
          </CustomContainer>
        </CustomContainer>
      <Box sx={{
        color: "#d3a4a4",
        width: "100%",
        justifyContent: "center",
        display:"flex",
        marginTop: 10
      }}>
        <Copyright/>
      </Box>
    </Box>
  );
};

export default Footer;

function Copyright() {
  return (
      <Typography variant="body2"  align="center">
        {'Powered by '}
        <Link href="https://beettechnologies.co.uk/">
          BEET Technologies Ltd.
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
  );
}
