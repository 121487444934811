import Hero from "../Hero";
import Properties from "../Properties";
import Partners from "../Partners";
import {styled} from "@mui/material";
import {Box} from "@mui/system";

function Home() {

    const CustomBox = styled(Box)(({ theme }) => ({
        padding: theme.spacing(10, 0, 10, 0),
        margin: theme.spacing(0, 2, 0, 2),
        [theme.breakpoints.down("md")]: {
            padding: "0",
        },
    }));

    return (
        <>
            <Hero/>
            <Properties/>
            <CustomBox>
                <Partners backgroundColor={"#fff"} />
            </CustomBox>
        </>
    );
}

export default Home;
