import { Box, Container, styled, Typography } from "@mui/material";
import React from "react";

const Menus = () => {

    const PropertiesTextBox = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    }));

    return (
        <Box sx={{ backgroundColor: "#F5FAFE", py: 10 }}>
            <Container>
                <PropertiesTextBox>
                    <Typography
                        sx={{ color: "#000339", fontSize: "35px", fontWeight: "bold" }}
                    >
                        Coming soon!
                    </Typography>
                    {/*<Typography sx={{ color: "#5A6473", fontSize: "16px", mt: 1 }}>*/}
                    {/*    We are thrilled to bring you with exiting events.*/}
                    {/*</Typography>*/}
                </PropertiesTextBox>

                {/*<PropertiesBox>*/}
                {/*    {menus.map((property) => (*/}
                {/*        <House*/}
                {/*            key={property.id}*/}
                {/*            img={property.img}*/}
                {/*            price={property.price}*/}
                {/*            address={property.address}*/}
                {/*            bedrooms={property.bedrooms}*/}
                {/*            bathrooms={property.bathrooms}*/}
                {/*            space={property.space}*/}
                {/*        />*/}
                {/*    ))}*/}
                {/*</PropertiesBox>*/}

                {/*<PropertiesTextBox>*/}
                {/*    <Typography*/}
                {/*        sx={{ color: "#000339", fontSize: "35px", fontWeight: "bold" }}*/}
                {/*    >*/}
                {/*        Main Course*/}
                {/*    </Typography>*/}
                {/*    <Typography sx={{ color: "#5A6473", fontSize: "16px", mt: 1 }}>*/}
                {/*        We are thrilled to bring you with exiting events.*/}
                {/*    </Typography>*/}
                {/*</PropertiesTextBox>*/}

                {/*<PropertiesBox>*/}
                {/*    {menus.map((property) => (*/}
                {/*        <House*/}
                {/*            key={property.id}*/}
                {/*            img={property.img}*/}
                {/*            price={property.price}*/}
                {/*            address={property.address}*/}
                {/*            bedrooms={property.bedrooms}*/}
                {/*            bathrooms={property.bathrooms}*/}
                {/*            space={property.space}*/}
                {/*        />*/}
                {/*    ))}*/}
                {/*</PropertiesBox>*/}


                {/*<PropertiesTextBox>*/}
                {/*    <Typography*/}
                {/*        sx={{ color: "#000339", fontSize: "35px", fontWeight: "bold" }}*/}
                {/*    >*/}
                {/*        Burgers, Pittas, Wraps*/}
                {/*    </Typography>*/}
                {/*    <Typography sx={{ color: "#5A6473", fontSize: "16px", mt: 1 }}>*/}
                {/*        We are thrilled to bring you with exiting events.*/}
                {/*    </Typography>*/}
                {/*</PropertiesTextBox>*/}

                {/*<PropertiesBox>*/}
                {/*    {menus.map((property) => (*/}
                {/*        <House*/}
                {/*            key={property.id}*/}
                {/*            img={property.img}*/}
                {/*            price={property.price}*/}
                {/*            address={property.address}*/}
                {/*            bedrooms={property.bedrooms}*/}
                {/*            bathrooms={property.bathrooms}*/}
                {/*            space={property.space}*/}
                {/*        />*/}
                {/*    ))}*/}
                {/*</PropertiesBox>*/}
            </Container>
        </Box>
    );
};

export default Menus;
