import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Navbar from "../Components/Navbar";
import Home from "../Components/Home";
import Register from "../Components/Register";
import Menus from "../Components/Menus";
import Careers from "../Components/Careers";
import Events from "../Components/Events";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";
import VIP from "../Components/VIP";
import JobDescription from "../Components/Careers/JobDescription";
import Partners from "../Components/Partners";
import Booking from "../Components/Booking";
import About from "../Components/About";
import TermsAndConditions from "../Components/Policies/TermsAndConditions";
import PrivacyPolicy from "../Components/Policies/PrivacyPolicy";
import DeliveryPolicy from "../Components/Policies/DeliveryPolicy";
import PrivateParties from "../Components/Parties/PrivateParties";

const Routing = () => {
    const [index, setIndex] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/") {
            setIndex(false);
        } else {
            setIndex(true);
        }
    }, [location]);

    return (
        <>
            {index && <Navbar />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/events" element={<Events />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/menus" element={<Menus />} />
                <Route path="/vip" element={<VIP />} />
                <Route path="/register" element={<Register />} />
                <Route path="/job-details/:id" element={<JobDescription />} />
                <Route path="/booking" element={<Booking />} />
                <Route path="/gardens" element={<Booking />} />
                <Route path="/sunday-roast" element={<Booking />} />
                <Route path="/private-parties" element={<PrivateParties />} />
                <Route path="/drinks" element={<Booking />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/delivery-policy" element={<DeliveryPolicy />} />
                <Route path="/partners" element={<Partners backgroundColor={"#fff"} />} />
                <Route path='/facebook' Component={() => {
                    window.location.href = 'https://www.facebook.com/people/BEETs-Lounge/61557945423210/';
                    return null;
                }}/>
                <Route path='/tiktok' Component={() => {
                    window.location.href = 'https://www.tiktok.com/@beets.lounge?_t=8lGIHwMhZEf&_r=1';
                    return null;
                }}/>
                <Route path='/snapchat' Component={() => {
                    window.location.href = 'https://t.snapchat.com/5Qlx4Trg';
                    return null;
                }}/>
            </Routes>
            <Footer />
        </>
    );
};

export default Routing;
